module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"MFO","short_name":"MFO","start_url":"/","background_color":"#FFFFFF","theme_color":"#000000","display":"standalone","icon":"src/resources/img/favicon/favicon.png","icon_options":{"purpose":"any maskable"},"cache_busting_mode":"query","include_favicon":true,"legacy":true,"theme_color_in_head":true,"cacheDigest":"930ed455aee675edd7ad3da87abf0e34"},
    },{
      plugin: require('../node_modules/@prismicio/gatsby-source-prismic-graphql/gatsby-browser.js'),
      options: {"plugins":[],"repositoryName":"myfreightonline","accessToken":"MC5YbXpnaWhRQUFDSUFsT1FC.77-977-9O--_vW4_77-9B0_vv71177-977-977-977-9Q--_ve-_ve-_vRtmJnjvv73vv73vv71WPu-_ve-_vTsU","defaultLang":"en-gb","lang":["en-gb","fr-fr"],"path":"/preview","previews":true,"sharpKeys":[{}]},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
