// import React from "react"
// import { RichText } from 'prismic-reactjs'

function prefixLink(doc, path) {
  return doc.lang === 'en-gb' ? path : `/${doc.lang}${path}`
}

const linkResolver = (doc) => {
  if (!doc) {
    return
  }
  if (doc.uid === "home") {
    return '/'
  }
  if (doc.uid === "contact") {
    return '/contact'
  }
  if (doc.uid === "news") {
    return '/news'
  }
  if (doc.uid === "mfo") {
    return '/mfo'
  }
  if (doc.type === 'service') {
    return prefixLink(doc, `/service/${doc.uid}`)
  }
  if (doc.type === 'article') {
    return prefixLink(doc, `/article/${doc.uid}`)
  }
  return prefixLink(doc, `/${doc.uid}`)
}

export default linkResolver;
